
#table-of-contents li{
    list-style:none;
    margin-left: 0px;
}

#table-of-contents header{
    height:32px;
    display:inline-block;
    line-height:32px;
    padding:0 1.618em;
    display:block;
    font-weight:bold;
    text-transform:uppercase;
    font-size:80%;
    color:#2980B9;
    white-space:nowrap
}

#table-of-contents ul{
    margin-bottom:0
}

#table-of-contents li.divide-top{
    border-top:solid 1px #404040
}

#table-of-contents li.divide-bottom{
    border-bottom:solid 1px #404040
}

#table-of-contents li.current{
    background:#e3e3e3
}

#table-of-contents li.current a{
    color:gray;
    border-right:solid 1px #c9c9c9;
    padding:0.4045em 2.427em
}

#table-of-contents li.current a:hover{
    background:#d6d6d6
}
    
#table-of-contents li a{
    /* color:#404040; */

    padding:0.4045em 1.618em;
    position:relative;

    /* background:#fcfcfc; */

    border:none;

    /* border-bottom:solid 1px #c9c9c9; */
    /* border-top:solid 1px #c9c9c9; */
    
    padding-left:1.618em -4px
}

#table-of-contents li.on a:hover,#table-of-contents li.current>a:hover{
    background:#fcfcfc
}

#table-of-contents li ul li a{
    /* background:#c9c9c9; */
    padding:0.4045em 2.427em
}

#table-of-contents li ul li ul li a{
    padding:0.4045em 3.236em
}

#table-of-contents li.current ul{
    display:block
}

#table-of-contents .local-toc li ul{
    display:block
}

#table-of-contents li ul li a{
    margin-bottom:0;
    color:#b3b3b3;
    font-weight:normal
}

#table-of-contents a{
    display:inline-block;
    line-height:18px;
    padding:0.4045em 1.618em;
    display:block;
    position:relative;
    font-size:90%;
    color:#b3b3b3;
    direction: ltr;
}

#table-of-contents a:hover{
    background-color:#4e4a4a;
    cursor:pointer
}


#table-of-contents{
    position:fixed;
    top:0;
    left:0;
    width:25%;
    overflow-x:hidden;
    overflow-y:scroll;
    height:100%;
    background:#343131;
    z-index:200;
    scrollbar-base-color: #1F1D1D;
    scrollbar-arrow-color: #b3b3b3;
    scrollbar-shadow-color: #1F1D1D;
    scrollbar-track-color : #343131;
}

#table-of-contents h2 a {
    display: none;
}

#table-of-contents h2{
    z-index:200;
    background-color:#715ab1;
    text-align:center;
    padding:0.809em;
    display:block;
    color:#fcfcfc;
    font-size: 100%;
    margin-bottom:0.809em}

@media screen and (max-width: 768px) {
    #table-of-contents {
        display: none;
        width: 60%;
    }

    #table-of-contents h2 a {
        display: block;
    }

    #table-of-contents:target {
        display: block;
    }

}
/* #text-table-of-contents {
    overflow:scroll;
    text-align: left; 
} */


ul.nav li ul li {
    display: none;
}

ul.nav li ul li ul li {
    display: none;
}

ul.nav li.active ul li {
    display: inline;
}

ul.nav li.active ul li ul li {
    display: inline;
}

ul.nav li.active ul li a {
    background-color: #E3E3E3;
    color: #8099B0;
    border-right:solid 1px #c9c9c9 !important;
}

ul.nav li.active ul li.active a {
    background-color: #C9C9C9;
    color: black !important;
    font-weight: bold !important;
}

ul.nav li.active ul li.active ul li.active a {
    color: black !important;
    font-weight: bold !important;
    display: block !important;
}

ul.nav li.active ul li.active ul li a {
    color: #808080 !important;
    font-weight: normal !important;
    display: block !important;
}

ul.nav li.active ul li ul li a {
    display: none !important;
}

/* / */
ul.nav li ul li ul li ul li {
    display: none !important; /* as long as nav is on multiple levels of ul */
    /* display: none; /* as long as nav is on multiple levels of ul *\/ */
}

ul.nav li.active > a {
    border-bottom:solid 1px #c9c9c9 !important; /* XXX Restrict it to 2nd level */
    border-right:solid 1px #c9c9c9 !important;
}

ul.nav li.active a {
    color: gray !important;
    font-weight:bold;
    background-color: white;
    border-right:solid 0px white !important;
}

ul.nav > li.active > a {
    color: black !important;
}

a{
    color:#2980B9;
    text-decoration:none;
    cursor:pointer
}

a:hover,a:active{
    outline:0;
}
    
a:hover{
    color:#3091d1
}

a:visited{
    color:#9B59B6
}

ul,ol,dl{
    line-height:24px;
    list-style-image:none;
    /* list-style:none; */
    margin:0px 0px 24px 0px;
    padding:0;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#text-table-of-contents {
    text-align-last: left;
}
