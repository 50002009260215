.row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; 
}
.row:before,
.row:after {
  content: " ";
  display: table; 
}
.row:after {
  clear: both; 
}
#screenshot {
    margin-bottom: 4em;
  }

  .wp {
    visibility: hidden; 
}
.wp-3 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; 
}
.slick-slide {
    outline: none;
}

.wp.animated {
    visibility: visible; 
}

code{
  background:#fbf8ef;
  border:solid 1px #e1e4e5;
  /* color:#000;  for clickable code */
  font-family:Consolas,"Andale Mono WT","Andale Mono","Lucida Console","Lucida Sans Typewriter","DejaVu Sans Mono","Bitstream Vera Sans Mono","Liberation Mono","Nimbus Mono L",Monaco,"Courier New",Courier,monospace;
  font-size:75%;
  max-width:100%;
  overflow-x:auto;
  padding:0 5px;
  white-space:nowrap;
}