h1,h2,h3,h4,h5,h6,legend{
    font-family:"Roboto Slab","ff-tisa-web-pro","Georgia",Arial,sans-serif;
    font-weight:700;
    margin-top:0;
}

h1{
    color: #4e3163;
    font-size:175%;
}

.subtitle{
    font-size:95%; /* of h1 */
}

h2{
    color: #4e3163;
    font-size:150%;
}

h3{
    color: #6c3163;
    font-size:125%;
}

h4{
    font-size:115%;
}

h5{
    font-size:110%;
}


#content{
    background:#fff;
    height:100%;
    margin-left:25%;
    /* margin:auto; */
    max-width:800px;
    min-height:100%;
    padding:1.618em 3.236em;
    text-align: left;
}

blockquote{
    background-color: #F0F0F0;
    border-left:5px solid #CCCCCC;
    font-style:italic;
    line-height:24px;
    margin:0px 0px 24px 0px;
    /* margin-left:24px; */
    padding: 6px 20px;
}


ul,ol,dl{
    line-height:24px;
    list-style-image:none;
    /* list-style:none; */
    margin:0px 0px 24px 0px;
    padding:0;
}


li{
    margin-left: 24px;
}

dd{
    margin:0;
}


#content .section ul,#content .toctree-wrapper ul,article ul{
    list-style:disc;
    line-height:24px;
    margin-bottom:24px
}

#content .section ul li,#content .toctree-wrapper ul li,article ul li{
    list-style:disc;
    margin-left:24px
}

#content .section ul li p:last-child,#content .toctree-wrapper ul li p:last-child,article ul li p:last-child{
    margin-bottom:0
}


#content .section ul li ul,#content .toctree-wrapper ul li ul,article ul li ul{
    margin-bottom:0
}

#content .section ul li li,#content .toctree-wrapper ul li li,article ul li li{
    list-style:circle
}

#content .section ul li li li,#content .toctree-wrapper ul li li li,article ul li li li{
    list-style:square
}

#content .section ul li ol li,#content .toctree-wrapper ul li ol li,article ul li ol li{
    list-style:decimal
}

#content .section ol li,#content ol li,article ol li{
    list-style:decimal;
    margin-left:24px
}

#content .section ol li ul,#content ol li ul,article ol li ul{
    margin-bottom:0
}

#content .section ol li ul li,#content ol li ul li,article ol li ul li{
    list-style:disc
}

dl dt{
    font-weight:bold;
}

dl p,dl table,dl ul,dl ol{
    margin-bottom:12px !important;
}

dl dd{
    margin:0 0 12px 24px;
}

@media screen and (max-width: 768px){
    #content{
        margin-left:0}

    #content #content{
        padding:1.618em}

    #content.shift{
        position:fixed;
        min-width:100%;
        left:85%;
        top:0;
        height:100%;
        overflow:hidden}
}
